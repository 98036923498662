<template>
  <div>

    <div class="swiper-bottom">
      <!--左侧学会动态图片-->
      <div class="master-dynamic-picture">
        <el-carousel height="350px" arrow="always">
          <el-carousel-item v-for="item in dynamicList" :key="item.id">
            <div class="dynamic-title">{{item.informName}}</div>
            <el-image @click="goToDynamicDetail(item.pictureId)" :src="baseUrl+item.pictureUrl" fit="scale-down"/>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!--4166am官网登录模块-->
      <div class="home-module">
        <el-tabs v-model="activeModule" class="demo-tabs" @tab-click="switchModule">
          <el-tab-pane :label="item.partName" :name="item.partId" v-for="item in moduleList" :key="item.partId">
            <div class="bottom" v-for="item1 in arrList.slice(0,7)" :key="item1.informId">
              <div class="news-item"  @click="modulepage(item1, item)">
                <span class="text" :title="item1.informName">
                  ★&nbsp;&nbsp;{{item1.informName}}
                </span>
                <span class="news-time">【{{item1.informDate}}】</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <span class="more-news" @click="partAbout(activeModuleDetail)">更多></span>
      </div>
    </div>

    <!--下方长条轮播图-->
    <smallSwiper style="margin-top: 20px"></smallSwiper>

    <!--新增小类模块-->
    <div class="others-list">
      <div class="others-item" v-for="item in partList" :key="item.portalPart.partId">
        <div class="up">
          <div class="l1">{{ item.portalPart.partName }}</div>
          <div class="r1" @click="partAbout(item)">更多>></div>
        </div>
        <div class="bottom" v-for="item1 in item.portalInforms.slice(0,5)" :key="item1.informId">
          <div class="news-item"  @click="partDetail(item1, item)">
            <span class="text" :title="item1.informName">
              ★&nbsp;{{item1.informName}}
            </span>
            <span class="news-time">【{{item1.informDate}}】</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import smallSwiper from './smallSwiper'
import {homeDynamic, showOthersTitle, homelshowTi, showPartInform, home1show, showPartInform2} from '../../../api/compoents.js'
export default {
  name: "appModule",
  components: {
    smallSwiper
  },

  data() {
    return {
      //baseUrl:'http://39.103.190.34:8181',
      baseUrl:'http://59.110.106.225:8181',
      titleList:[],
      resultList:[],
      partList:[],
      dynamicList:[],
      moduleList:[],   // 4166am官网登录模块数组
      activeModule:'',   // 所点击的模块
      activeModuleDetail:{}, // 所点击模块的所有信息
      newsList:[],   // 所点击模块的新闻列表
      arrList:[],


    }
  },
  created() {
    this.getMasterDynamic()
    this.getOtherTitles()
    this.getShowPartInform()
  },
  methods:{
    // 获取学会动态图片
    getMasterDynamic() {
      homeDynamic().then(res=>{
        if(res.data.code===200) {
          this.dynamicList=res.data.allPicByIsShow
          console.log('dynamicList',this.dynamicList)
        } else {
          this.$message.warning(res.data.msg)
        }

      })
    },
    // 点击跳转动态图片详情
    goToDynamicDetail(id) {
      const href = this.$router.resolve({
        // 传递参数使用query的话，指定path或者name都行，但使用params的话，只能使用name指定
        path: '/page',
        query: {
          id: id,
          names: '学会动态图',
          logn: 'B'
        }
      })
      window.open(href.href, '_blank')
    },
    // 四个4166am官网登录其他模块
    async getOtherTitles() {
      try {
        const res = await showPartInform2();
        for(let i = 0;i<=res.data.data.length-1;i++){
          let list = res.data.data[i].portalPart
          this.moduleList[i] = list
        }
        console.log('this.moduleList',this.moduleList)
        this.arrList = res.data.data[0].portalInforms
        this.activeModule = this.moduleList[0].partId
        this.activeModuleDetail=res.data.data[0]
      } catch (error) {
        console.log('请求数据出错', error);
      }
    },
    // 切换4166am官网登录模块内容
    async switchModule(tab) {
      let res = await showPartInform2();
      for(let i = 0;i<=res.data.data.length-1;i++){
          if(tab.paneName === res.data.data[i].portalPart.partId){
            this.activeModuleDetail=res.data.data[i]
            console.log('this.activeModuleDetail',this.activeModuleDetail)
            this.arrList = res.data.data[i].portalInforms
          }
        }
    },

    // 获取4166am官网登录新增的其他小类模块
    getShowPartInform() {
      showPartInform().then(res=>{
        this.partList=res.data.data
      })
    },

    // 4166am官网登录固定模块跳转更多页面
    modulepage1(i) {

      const href = this.$router.resolve({
          // 传递参数使用query的话，指定path或者name都行，但使用params的话，只能使用name指定
          path: '/about',
          query: {
            id: i.titleId,
            names: i.title,
            logn: 'D'
          }
        }
      )
      window.open(href.href, '_blank')
    },
    // 4166am官网登录固定模块跳转详情页
    modulepage(i, father) {
      const href = this.$router.resolve({
        // 传递参数使用query的话，指定path或者name都行，但使用params的话，只能使用name指定
        path: '/page',
        query: {
          id: i.informId,
          names: father.partName,
          logn: 'A'
        }
      })
      window.open(href.href, '_blank')
    },



    // 4166am官网登录新增小类模块跳转更多页面
    partAbout(i) {
      home1show().then(res=>{
        const navList=res.data.allNavigationList
        console.log(navList);
        console.log(i);
        let result=navList.find(item=>item.navigationId===i.portalPart.navigationId)
        let navigationName=''
        if(result) {
          navigationName=result.navigationName
        } else {
          navigationName=i.portalPart.partName
        }
        console.log(navigationName);
        const routeUrl = this.$router.resolve(`/about?id=${i.portalPart.navigationId}&navigationName=${navigationName}&name=${i.portalPart.partName}&partId=${i.portalPart.partId}&logn=${'C'}&location=${'home'}`)
        window.open(routeUrl.href, '_blank')
      })
    },

    // 4166am官网登录新增小类模块跳转详情页
    partDetail(i,f) {
      const routeUrl = this.$router.resolve(`/page?id=${i.informId}&name=${f.portalPart.partName}&logn=${'A'}`)
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .el-tabs__item {
  text-align: center;
  font-size: calc(var(--w) * .3);
  width:  calc(var(--w) * 2);
}

.swiper-bottom {
  --w: calc(var(--xd--fontsize) * 2);
  margin-top:40px;
  display: flex;
  justify-content: space-evenly;
  .master-dynamic-picture {
    width: 45vw;

    ::v-deep .el-carousel__indicators {
      left: unset;
      transform: unset;
      right: 2%;
    }
    ::v-deep .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    .dynamic-title {
      width: 100%;
      background-color: rgba(17, 15, 15, 0.44);
      color: #fff;
      font-size: 16px;
      padding:5px 20px;
      position: absolute;
      bottom: 0;
      z-index:1
    }
  }
  .home-module {
    position: relative;
    width: 45vw;
    .more-news {
      cursor:pointer;
      position: absolute;
      top:0;
      right:0;
      font-size: 16px;
      display: inline-block;
      padding:10px 20px;
    }
  }
}

.others-list {
  --w: calc(var(--xd--fontsize) * 2);
  display: flex;
  flex-wrap: wrap; /* 自动换行 */
  justify-content: space-evenly;
  width: 95vw;
  margin:0 auto;
  .others-item {
    width: 43vw;
    //margin:calc(var(--w) * .4) calc(var(--w) * .4)
  }
}
.up {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: calc(var(--w) * .03) solid rgb(194, 193, 193);
  padding: 0 calc(var(--w) * .5);
  font-size: calc(var(--xd--fontsize) * .7);
  //margin-bottom: calc(var(--w) * .3);
  .l1 {
    padding: calc(var(--xd--fontsize) * .5) 0;
    font-weight: 700;
    border-bottom: 4px solid #3e6db5;
  }
  .r1 {
    padding: calc(var(--xd--fontsize) * .5) 0;
    padding-top: calc(var(--xd--fontsize) * .8);
    cursor: pointer;
    font-size: calc(var(--xd--fontsize) * .5);
  }
}
.bottom {
  cursor: pointer;
  .news-item {
    display: flex;

    justify-content: flex-start;
    //height:calc(var(--w) * .45);
    margin: calc(var(--w) * .33) 0;
    padding-right: calc(var(--w) * .1);
    padding-left: calc(var(--w) * .3);
    /* height: calc(var(--w) * 5); */
    line-height: calc(var(--w) * .3);
    font-size: calc(var(--w) * .26);
    letter-spacing: calc(var(--xd--fontsize) * .02);
    .text {
      flex:1;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: left;
      /* 上面六行代码实现多行文本超出部分显示省略号  */
    }
    .text:hover {
      color: #2292e3;
    }
    .news-time {
      padding-right:calc(var(--xd--fontsize) * .2);
      text-align: right;
    }
  }

}


//其他平台
.platform {
  width: 80vw;
  margin:calc(var(--xd--fontsize) * .6) auto 0;
  .up {
    margin: 0 calc(var(--w) * .1);
    border-bottom: calc(var(--xd--fontsize) * .06) solid rgb(194, 193, 193);
    padding: 0 calc(var(--xd--fontsize) * .9);
  }
  .p-bottom {
    width: 70vw;
    display: flex;
    justify-content: space-between;
    //padding:calc(var(--w)*.06) calc(var(--w)*.05) ;
    margin:10px auto;
    li {
      font-size: calc(var(--xd--fontsize) * .6);
      border-radius: var(--y-com-radius0);
      margin: calc(var(--w) * .5);
      transition: all .25s;
      span {
        font-size: calc(var(--xd--fontsize) * .7);
      }
      a {
        text-align: center;
        transition: all .25s;
        display: block;
        width: 100%;
        color: #000;
        padding: calc(var(--xd--fontsize) * .35) 0;
      }
    }
    li:hover a{
      color: #3ba1ea !important;
    }
  }
}


//底部信息
.home-bottom {
  height: calc(var(--xd--fontsize) * 6);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin:calc(var(--xd--fontsize) * 4)  auto 0;
  background-color: #26425e;
  color: #fff;
  bottom: 0;
  left: 0;
  user-select: none;
  .l {
    font-size: calc(var(--xd--fontsize) * .46);
    li {
      line-height: calc(var(--xd--fontsize) * .75);
      margin-right: calc(var(--xd--fontsize) * .6);
    }
  }
  .r {
    width: calc(var(--w) * 1.69);
    height: calc(var(--w) * 1.69);
    overflow: hidden;
    margin-top: calc(var(--w) * .3);
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width:767px) {
  .swiper-bottom {
    display: flex;
    flex-direction: column;
    .master-dynamic-picture {
      width: 90vw;
      height:57vw;
      margin:0 auto;
      overflow:hidden;
    }
    .home-module {
      width: 90vw;
      margin:20px auto;
      ::v-deep .el-tabs__nav {
        width: 80%!important;
        margin-left:20px
      }
      ::v-deep .el-tabs__item {
        width: 20%;
        padding: 0 10px 0 0!important;
        margin-right:10px;
      }
      .more-news {
        font-size: 12px;
      }
    }
  }
  .others-list {
    .others-item {
      width: 90vw;
      //margin:calc(var(--w) * .4) calc(var(--w) * .4)
    }
  }
}



</style>
